import styled from 'styled-components';

import { FlexBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';

export const AttractionsList = styled.ul``;

export const AttractionsListItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const AttractionsListItemIcon = styled(FlexBox)`
  margin-right: 1.125rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.secondary.hover};
`;

export const AttractionsListItemTitle = styled(Typography)`
  &:first-child {
    margin-left: 2.625rem;
  }
`;

export const AttractionsListItemDistance = styled(Typography)`
  padding-left: 1rem;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
