import React from 'react';

import { AttractionIconMode } from './enums';
import { IconSignPost, IconMap, IconRouting } from '../../../assets/svg';

export const attractionsIconSwitch = (param: AttractionIconMode | null) => {
  switch (param) {
    case AttractionIconMode.Sign:
      return <IconSignPost />;
    case AttractionIconMode.Map:
      return <IconMap />;
    case AttractionIconMode.Pin:
      return <IconRouting />;
    default:
      return null;
  }
};
