import styled from 'styled-components';

import { Inner, Box, FlexBox } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';
import Link from '../../atoms/Link';

export const Location = styled(Inner)``;

export const LocationHead = styled(Box)`
  max-width: 23rem;
`;

export const LocationMap = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 23.75rem;
  margin-top: 3rem;

  @media ${media.phone} {
    height: 33.5rem;
    margin-top: 5rem;
  }
`;

export const LocationInfo = styled(FlexBox)`
  flex-direction: column-reverse;
  margin-top: 3rem;

  @media ${media.phone} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3.75rem;
  }
`;

export const LocationAddress = styled.div`
  width: 100%;
  max-width: 24.5rem;

  @media ${media.maxPhone} {
    margin-top: 3rem;
  }
`;

export const LocationAddressTitle = styled(Typography)``;

export const LocationAddressSubtitle = styled(Typography)`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const LocationAddressAction = styled(Link)`
  margin-top: 3rem;
`;

export const LocationAttractions = styled.div`
  width: 100%;

  @media ${media.phone} {
    margin-top: 5rem;
    margin-left: 2rem;
    max-width: 24.5rem;
  }
`;
