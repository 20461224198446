import React, { Suspense, lazy } from 'react';

import * as Styled from './styles';
import { LocationProps } from './types';

import Heading from '../../molecules/Heading';
import AttractionsList from '../../molecules/AttractionsList';
import Loader from '../../atoms/Loader';
import useInViewport from '../../../hooks/useInViewport';

const Map = lazy(() => import('../../atoms/Map'));

const Location: React.FC<LocationProps> = ({ data }) => {
  const { title, subtitle, address, addressSubtitle, buttonLink, items, coordinates } = data;
  const { ref, isMounted } = useInViewport();

  return (
    <Styled.Location>
      <Styled.LocationHead>
        <Heading {...{ title, subtitle }} />
      </Styled.LocationHead>
      <Styled.LocationMap {...{ ref }}>
        {isMounted ? (
          <Suspense fallback={<Loader isVisible />}>
            <Map {...{ coordinates }} />
          </Suspense>
        ) : null}
      </Styled.LocationMap>
      <Styled.LocationInfo>
        <Styled.LocationAddress>
          <Styled.LocationAddressTitle variant="headingS">{address}</Styled.LocationAddressTitle>
          {addressSubtitle && (
            <Styled.LocationAddressSubtitle variant="textM">
              {addressSubtitle}
            </Styled.LocationAddressSubtitle>
          )}
          {buttonLink?.link && (
            <Styled.LocationAddressAction {...buttonLink.link}>
              {buttonLink.label}
            </Styled.LocationAddressAction>
          )}
        </Styled.LocationAddress>
        {items && (
          <Styled.LocationAttractions>
            <AttractionsList {...{ items }} />
          </Styled.LocationAttractions>
        )}
      </Styled.LocationInfo>
    </Styled.Location>
  );
};

export default Location;
