import { graphql } from 'gatsby';
import React from 'react';

import { getHref } from '../../../../utils/sanityTypes';
import { AttractionsListItemProps } from '../../../molecules/AttractionsList/types';

import Location from '../../Location';
import { LocationSectionProps } from './types';

const LocationSection: React.VFC<LocationSectionProps> = ({
  title,
  subtitle,
  address,
  addressSubtitle,
  buttonLink,
  items,
  coordinates,
}) => {
  const { latitude, longitude } = coordinates ?? {};
  const { link, buttonLabel } = buttonLink ?? {};

  return (
    <Location
      data={{
        subtitle: subtitle ?? '',
        title: title ?? '',
        address: address ?? '',
        addressSubtitle: addressSubtitle ?? '',
        coordinates: [latitude ?? 0, longitude ?? 0],
        buttonLink:
          {
            link: { to: getHref(link) },
            label: buttonLabel ?? '',
          } ?? {},
        items: (items ?? []).map<Omit<AttractionsListItemProps, 'iconMode'>>((point) => {
          const { _key: pointKey, iconMode, title: pointTitle, distance } = point ?? {};

          return {
            key: pointKey ?? '',
            title: pointTitle ?? '',
            iconMode: iconMode ?? '',
            distance: distance ?? '',
          };
        }),
      }}
    />
  );
};
export const fragment = graphql`
  fragment LocationSectionFragment on SanityLocationSection {
    _key
    _type
    subtitle
    title
    address
    addressSubtitle
    buttonLink {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    items {
      _key
      iconMode
      title
      distance
    }
    coordinates {
      latitude: lat
      longitude: lng
      alt
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default LocationSection;
