import styled, { css } from 'styled-components';
import { m } from 'framer-motion';
import { rgba } from 'polished';

import { spinAnimation } from '../../../common/animations';
import { positionFullStyles } from '../../../common/mixins';
import { LoaderProps } from './types';

export const Loader = styled.div<LoaderProps>`
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: flex;
    `}
`;

export const LoaderInner = styled.span`
  width: 5rem;
  height: 5rem;
  border-top: 0.25rem solid ${({ theme }) => theme.colors.primary.hover};
  border-right: 0.25rem solid transparent;
  border-radius: 50%;
  animation: 1s ${spinAnimation} linear infinite;
`;

export const LoaderWrapper = styled(m.div)`
  ${positionFullStyles}
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 11.5rem;
  background-color: ${({ theme }) => rgba(theme.colors.neutral.neutral10, 0.75)};
  z-index: 2;
`;
