import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useInViewport = () => {
  const { ref, inView } = useInView();
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (inView && !isMounted) {
      setIsMounted(true);
    }
  }, [inView, isMounted]);

  return { ref, isMounted };
};

export default useInViewport;
