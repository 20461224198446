import React from 'react';

import { AttractionsListProps } from './types';
import * as Styled from './styles';
import { attractionsIconSwitch } from './const';

const AttractionsList: React.FC<AttractionsListProps> = ({ items }) => (
  <Styled.AttractionsList>
    {items.map(({ key, iconMode, title, distance }) => (
      <Styled.AttractionsListItem {...{ key }}>
        {iconMode && (
          <Styled.AttractionsListItemIcon as="span">
            {attractionsIconSwitch(iconMode)}
          </Styled.AttractionsListItemIcon>
        )}
        <Styled.AttractionsListItemTitle variant="textL">{title}</Styled.AttractionsListItemTitle>
        {distance && (
          <Styled.AttractionsListItemDistance variant="textM">
            {distance}
          </Styled.AttractionsListItemDistance>
        )}
      </Styled.AttractionsListItem>
    ))}
  </Styled.AttractionsList>
);

export default AttractionsList;
