import React from 'react';

import * as Styled from './styles';
import { LoaderProps } from './types';

const Loader: React.FC<LoaderProps> = ({ isVisible }) => (
  <Styled.Loader {...{ isVisible }}>
    <Styled.LoaderInner />
  </Styled.Loader>
);

export default Loader;
